import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step context remove`}</strong>{` -- remove a context and all associated configuration`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step context remove <name> [--force]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step context remove`}</strong>{` command removes a context, along
with all associated configuration, from disk.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`name`}</inlineCode>{`
The name of the context to remove .`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Remove a context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context remove alpha-one
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      